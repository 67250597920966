<template lang="pug">
.layout
  NuxtLoadingIndicator(:duration="1000", color="repeating-linear-gradient(90deg, rgba(255,181,213,1) 0%, rgba(238,61,138,1) 75%, rgba(159,0,69,1) 100%)")

  .content
    slot

  .footer
    Footer
</template>
<script setup lang="ts">
const route = useRoute()
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.layout
  position: relative
  width: 100%

  .content
    position: relative
    width: 100%
    flex-grow: 1
    min-height: calc(100vh - 124px - 352px)
    box-sizing: border-box

  @include phablet
    .content
      min-height: 0
      padding-bottom: 32px
</style>
